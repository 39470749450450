<div class="form-group mobile" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss" class="with-prefix">
    <mat-label>{{ title }}</mat-label>
    <span class="phone-prefix" matPrefix>+33</span>
    <input
      appPhoneTrim
      matInput
      (change)="change()"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
      [placeholder]="'0622334455'"
      maxlength="10"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <span class="error-mark" *ngIf="control.touched && control.errors && !control.hideErrorMark">
      <fa-icon [icon]="faClose"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.mobileValidator"> {{ 'HOME.MobilePhoneError' | labelText }} </span>
      <span *ngIf="control.errors?.phoneRemoteValidator">
        {{ 'HOME.RecommandationWrongPhoneNumber1' | labelText }} {{ control.phoneTypeText }} {{ 'HOME.RecommandationWrongPhoneNumber2' | labelText }}
      </span>
    </mat-error>
  </mat-form-field>
</div>
