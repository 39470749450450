<div class="form-group inline" [class.required]="isRequired" [class.fraisInclus]="fraisInclus" [class.aCeJour]="aCeJour" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss" [class.green-border]="disabledGreenBorder">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appTrim
      mask
      [mask]="control.separator"
      decimalMarker=","
      thousandSeparator=" "
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      [attr.maxlength]="maxLength"
      class="form-control"
      [formControlName]="controlName"
      [placeholder]="placeholder"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error class="number-error" *ngIf="!control.hideErrorMessages">
      <span *ngIf="control.errors?.required">
        {{ control.requiredErrorMessage }}
      </span>
      <span *ngIf="control.errors?.minlength">{{ 'HOME.RecommandationMinValueText' | labelText }} {{ control.minLength }}</span>
      <span *ngIf="control.errors?.min">{{ control.minValueErrorMessage }}</span>
      <span *ngIf="control.errors?.max">{{ control.maxValueErrorMessage }}</span>
    </mat-error>
  </mat-form-field>

  <span class="fraisInclus text-default" *ngIf="fraisInclus">
    {{ 'PAYMENTS.EurosFeesIncluded' | labelText }}
  </span>

  <span class="aCeJour text-default" *ngIf="aCeJour"> € à ce jour. </span>
  <!-- <div class="small" *ngIf="control.invalid && control.touched">
        <div class="text-danger small" *ngIf="control.errors?.required">*Required</div>
        <div class="text-danger small" *ngIf="control.errors?.minlength">*Min value should be {{ control.minLength }}</div>
    </div> -->
</div>
